import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailNaver02 from "../components/DetailNaver02";
import GoBackNaver from "../components/GoBackNaver";


function Naver02() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackNaver />      
      <DetailNaver02 />      
    </>    
  )
}

export default Naver02;