import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import Category from "../components/Category";
import ListPackage from "../components/ListPackage";
import ListAdvertising from "../components/ListAdvertising";
import ListYoutube from "../components/ListYoutube";
import ListNaverBlog from "../components/ListNaverBlog";
import ListSns from "../components/ListSns";
import ListHomepage from "../components/ListHomepage";
// import TopPopup from "../components/TopPopup";

function Detail() {
  return (
  <>
    <NavDetail />
    <DetailBanner />
    <Category />
    <ListPackage />
    <ListAdvertising />
    <ListYoutube />
    <ListNaverBlog />
    <ListSns />
    <ListHomepage />
    {/* <TopPopup />   보류     */}
  </>  
  );
}

export default Detail;