import styled from "styled-components";
import { Link } from "react-router-dom";
import homepage01 from "../img/homepage/hp01.png";
import homepage02 from "../img/homepage/hp02.png";

const Wrapper = styled.div`
  margin-bottom: 6vh;
`;
const MarkContainer = styled.div`
  max-width: 1000px;  
  margin: auto;
  position: relative;
  h2 {    
    font-size: 35px;
    padding-top: 7vh;
    @media screen and (max-width:1000px) {
      font-size: 28px;
    }
    @media screen and (min-width:500px) and (max-width:1300px) {
    padding-top: 10vh;
    //4k 사이즈에서 높이 깨지는 것 방지
    }  
  }
  @media screen and (max-width: 500px) {
    max-width: 380px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    max-width: 500px;
  }
`;
const ProuductList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 5vh 0;
  @media screen and (max-width:500px) {       
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width:500px) and (max-width:1000px){
    grid-template-columns: repeat(2, 1fr);
  }  
`;
const Homepage = styled.li`  
  width: 320px;
  height: 320px;
  position: relative;  
  overflow: hidden;
  margin-bottom : 5vh;
  border-radius: 10px; 
  @media screen and (max-width:500px) {    
    width: 180px;
    height: 180px;    
  }
  @media screen and (min-width:500px) and (max-width: 1000px) {
    width: 240px;
    height: 240px;    
  }   
`;
const BgCover = styled.div`
  width: 100%;
  height: 100%;  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease;
  cursor: pointer;  
  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  } 
`;
const ListTitle = styled.div`
  position: absolute;  
  padding: 1% 5%;
  width: 100%;
  bottom: 0;  
  background-color: rgba(255, 255, 255, 0.8);
  p {
    line-height: 1.5;
    @media screen and (max-width:500px) {      
      font-size: 0.8rem;    
    }
  }
  
`;


function ListHomepage() {
  return (
  <Wrapper id="homepage">    
    <MarkContainer>
      <h2>홈페이지 마케팅</h2>
      <ProuductList>       
        <Homepage>
          <Link to = "/detail/homepage01">
            <BgCover style={{backgroundImage: `url(${homepage01})`}}/>
            <ListTitle>
              <p>홈페이지 - 싱글 페이지</p>
              <p>1,100,000원 (1건 단위)</p>
            </ListTitle>
          </Link>            
        </Homepage>
        <Homepage>
          <Link to = "/detail/homepage02">
            <BgCover style={{backgroundImage: `url(${homepage02})`}}/>
            <ListTitle>
              <p>홈페이지 - 유튜브 연동 페이지</p>
              <p>3,300,000원 (1건 단위)</p>
            </ListTitle>
          </Link>            
        </Homepage>
      </ProuductList>      
    </MarkContainer>
  </Wrapper>
  );
}

export default ListHomepage;