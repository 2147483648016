import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

const Wrapper = styled.div`
  max-width: 850px;
  margin: 2vh auto; 
`;
const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: auto;
  @media screen and (max-width:500px) {
    width: 80vw;           
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width:500px) and (max-width:1000px){
    width: 70vw;    
    grid-template-columns: repeat(3, 1fr);
  }     
`;
const Category = styled.li`
`;
const Btn = styled.div`
  width: 160px;
  height: 45px;  
  border: 1px solid #e23e3e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 10px;    
    background-color: rgba(226, 62, 62, 0.1);   
  }  
  @media screen and (max-width:500px) {
    width: 150px;
    height: 40px;
  }  
`;
const Text = styled.p`
  text-align: center;
  line-height: 1.2;      
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;   
  }
`;

function GoBackYoutube() {
  return (
    <Wrapper>
      <Container>
        <Category>
          <HashLink to = "/detail/#youtube">
            <Btn>
              <Text>전체 상품</Text>
            </Btn>
          </HashLink> 
        </Category>
        <Category>
          <HashLink to = "/detail/package02">
            <Btn>
              <Text>유튜브 패키지</Text>  
            </Btn>                
          </HashLink>
        </Category>
        <Category>
          <HashLink to = "/detail/youtube01">
            <Btn>
              <Text>유튜브 채널 세팅</Text>  
            </Btn>              
          </HashLink> 
        </Category>
        <Category>
          <HashLink to = "/detail/youtube02">
            <Btn>
              <Text>유튜브 채널 디자인</Text>  
            </Btn>             
          </HashLink> 
        </Category>
        <Category>          
          <HashLink to = "/detail/youtube03">
            <Btn>
              <Text>유튜브 라이브스트리밍</Text>
            </Btn>                          
          </HashLink> 
        </Category>
        <Category>
          <HashLink to = "/detail/youtube04">
            <Btn>
              <Text>유튜브 인/아웃트로</Text>  
            </Btn>              
          </HashLink> 
        </Category>
        <Category>
          <HashLink to = "/detail/youtube05">
            <Btn>
              <Text>유튜브 소개 홍보 영상</Text>   
            </Btn>             
          </HashLink>
        </Category>
        <Category>          
          <HashLink to = "/detail/youtube06">
            <Btn> 
              <Text>유튜브 동영상 콘텐츠</Text>    
            </Btn>          
          </HashLink>           
        </Category>
        <Category></Category>        
      </Container>              
    </Wrapper>
  );
}

export default GoBackYoutube;