import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailNaver01 from "../components/DetailNaver01";
import GoBackNaver from "../components/GoBackNaver";


function Naver01() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackNaver />      
      <DetailNaver01 />      
    </>    
  )
}

export default Naver01;