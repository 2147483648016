import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailYoutube01 from "../components/DetailYoutube01";
import GoBackYoutube from "../components/GoBackYoutube";


function Youtube01() {
  return(
    <>
      <NavDetail />
      <DetailBanner /> 
      <GoBackYoutube />     
      <DetailYoutube01 />      
    </>    
  )
}

export default Youtube01;