import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailNaver03 from "../components/DetailNaver03";
import GoBackNaver from "../components/GoBackNaver";


function Naver03() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackNaver />      
      <DetailNaver03 />      
    </>    
  )
}

export default Naver03;