import styled from "styled-components";
import CountUp from "react-countup";

const Wrapper= styled.div`
  background-color:rgb(255, 185, 167,0.3);  
`;
const CountContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 3vh 0;
  text-align: center;
  line-height: 1.2;
  color: rgba(0,0,0,0.8);
  @media screen and (max-width: 500px) {
    max-width: 300px;
    span {
      line-height: 1.3;
    }
  }
`;
const Counting = styled.span`
  font-size: 1.5rem;
  text-align: center;  
`;


function FigureCountUp() {
  return (    
    <Wrapper id = "service">
      <CountContainer>
        <span>누적&nbsp;</span>
        <CountUp start={0} end={41615} delay={0} duration={3} separator=",">
          {({ countUpRef }) => (<Counting ref = {countUpRef} />)}
        </CountUp>
        <span>명,&nbsp;</span>
        <CountUp start={0} end={297} delay={0} duration={3} separator=",">
          {({ countUpRef }) => (<Counting ref = {countUpRef} />)}
        </CountUp>
        <span>개 기관이 고팀장 서비스를 이용하고 있습니다.</span>
      </CountContainer>
    </Wrapper>
  );
}

export default FigureCountUp;