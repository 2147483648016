import styled from "styled-components";
import kakaoTalk from "../img/kakaolink_btn_medium_ov.png";

const KakaoPopup = styled.a`
  display: block;
  width: 68px;
  height: 68px;  
  position: fixed;  
  right: 14px;
  bottom: 14px;
  border-radius: 25%;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);  
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  div {
    width: 0px;
    height: 40px;
    background-color:rgb(255,255,255,0.8);
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    right: 55px;
    bottom: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;    
    line-height: 40px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
    p {
      text-align: center;
      vertical-align: middle;
    }
  }
  &:hover div {
    width: 200px;
  }
  @media screen and (max-width:800px) {
    width: 48px;
    height: 48px;
    div {
      right: 47px;
    }
  }
`;

function KakaoChatPopup() {
  return (
    <KakaoPopup href = "https://pf.kakao.com/_xbxaSpM/chat">      
      <div>
        <p>카카오톡 채팅 상담하기</p>
      </div>
      <img src = {kakaoTalk} alt= "kakao_chat" />   
    </KakaoPopup>
  );
}

export default KakaoChatPopup;