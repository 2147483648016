import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailMessage03 from "../components/DetailMessage03";
import GoBackAdvertising from "../components/GoBackAdvertising";

function Message03() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackAdvertising />      
      <DetailMessage03 />      
    </>    
  )
}

export default Message03;