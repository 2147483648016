import styled from "styled-components";
import logo from "../img/고팀장 로고.png";

const Wrapper = styled.footer`  
  border-top: 1px solid #dcdfe6;
  padding: 5vh 0;
`;
const FooterContainer = styled.div`
  max-width: 1000px;
  margin:auto;   
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    text-align: center;
  }
`;
const LeftFooter = styled.ul`     
  li {      
    font-size: 14px;    
    margin-bottom: 1vh;
    img {
      width: 80px;
      height: 30px;
    }
    p {
      line-height: 1.6;
      color: #636363;
      span {
        font-weight: bold;
        font-size: 15px;
        color: #636363;
      }
    }     
  }  
`;
const RightFooter = styled(LeftFooter)`
  color: #636363;
`;

function Footer() {
  return (
    <Wrapper>
      <FooterContainer>
        <LeftFooter>
          <li>
            <img src={logo} alt="logo" />
          </li>
          <li>
            <p>고팀장 고객센터</p>
            <p>            
              <a href = 'tel: 070-8779-7288'>Tel : 070-8779-7288</a> 
              <a href = "https://pf.kakao.com/_xbxaSpM/chat"><span> <u>채팅하기</u></span></a>
            </p>        
            <p>AM 10:00 ~ PM 10:00 (토, 일, 공휴일 휴무)</p>        
          </li>
        </LeftFooter>
        <RightFooter>
          <li>
            <p>회사명 : ‌크리액션 | 대표 : 최정욱</p>
            <p>사업자등록번호 : 230-01-26286</p>
            <p>통신판매업신고번호 : 2021-서울강남-00106호</p>
            <p>본점 : 서울 강남구 논현로76길 12, 2층</p>
            <p>2호점 : 서울 강남구 논현로79길 8, 7층</p> 
          </li>      
          <li>
            &copy;크리액션. All Right reserved        
          </li>        
        </RightFooter>       
      </FooterContainer>
    </Wrapper>
  );
}

export default Footer;