import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailYoutube02 from "../components/DetailYoutube02";
import GoBackYoutube from "../components/GoBackYoutube";

function Youtube02() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackYoutube />       
      <DetailYoutube02 />      
    </>    
  )
}

export default Youtube02;