import styled from "styled-components";

const Wrapper = styled.div`
  padding: 8vh 0;
  background-color: rgba(248, 159, 159, 0.2);   
`;
const Title = styled.h1`  
  font-size: 40px;
  text-align: center;
  @media screen and (max-width:500px) {
      font-size: 2rem;
    }
  @media screen and (min-width:500px) and (max-width: 1000px) {
    font-size: 2.5rem;      
  }
`;
const AskBanner = styled.div`
  max-width: 500px;  
  height: 350px;
  margin: 7vh auto;
  position: relative;    
  border-radius: 10px;
  background-color: white;  
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);  
  @media screen and (max-width:500px) {    
    width: 350px;
    height: 300px;
  }  
`;
const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
`;

const Text = styled.div`     
  p {
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
  }
  @media screen and (max-width:500px) {
    p {      
      line-height: 1.5;
    }
  } 
  //4k 사이즈 반응형
  @media screen and (min-width: 800px) and (max-width:1300px) {
    p {      
      line-height: 1.5;
    }
  }    
`;
const ChatLink = styled.a``;
const SubmitBtn = styled.div`  
  margin: auto;
  margin-top: 5vh;
  width: 130px;
  height: 40px;  
  border-radius: 10px;  
  background-color:  #e95050;
  display: flex;
  justify-content: center;
  align-items: center;    
  text-align: center;
  color:white;   
  &:hover {     
    box-shadow: 1px 3px 5px rgb(0, 0, 0, 0.3);
  }  
`;

function Ask() {
  return (
    <Wrapper id = "ask">
      <Title>문의하기</Title>
      <AskBanner>
        <ContentContainer>
          <Text>
            <p>고팀장 서비스에 대해</p>
            <p>더 궁금한 것이 있나요??</p>
          </Text>        
          <ChatLink href = "https://pf.kakao.com/_xbxaSpM/chat">
            <SubmitBtn>상담 문의하기</SubmitBtn>
          </ChatLink>
        </ContentContainer>
      </AskBanner>
    </Wrapper>
  );
}

export default Ask;