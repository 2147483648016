import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailYoutube04 from "../components/DetailYoutube04";
import GoBackYoutube from "../components/GoBackYoutube";


function Youtube04() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackYoutube />      
      <DetailYoutube04 />      
    </>    
  )
}

export default Youtube04;