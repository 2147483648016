import { Switch, Route } from "react-router-dom";
import Home from "./Routes/Home";
import Detail from "./Routes/Detail";
import Footer from "./components/Footer";
import KakaoChatPopup from "./components/KakaoChatPopup";
import Message01 from "./Routes/Message01";
import Message02 from "./Routes/Message02";
import Message03 from "./Routes/Message03";
import Youtube01 from "./Routes/Youtube01";
import Youtube02 from "./Routes/Youtube02";
import Youtube03 from "./Routes/Youtube03";
import Youtube04 from "./Routes/Youtube04";
import Youtube05 from "./Routes/Youtube05";
import Youtube06 from "./Routes/Youtube06";
import Naver01 from "./Routes/Naver01";
import Naver02 from "./Routes/Naver02";
import Naver03 from "./Routes/Naver03";
import Sns01 from "./Routes/Sns01";
import Homepage01 from "./Routes/Homepage01";
import Homepage02 from "./Routes/Homepage02";
import Package01 from "./Routes/Package01";
import Package02 from "./Routes/Package02";
import Package03 from "./Routes/Package03";


function App() {
  const onContextMenu = (e) => {
    e.preventDefault();
  }
  const onCopy = (e) => {
    e.preventDefault();
    return false;
  }
  const onPaste = (e) => {
    e.preventDefault();
    return false;
  }  
  return (
    <div onContextMenu={onContextMenu} onCopy={onCopy} onPaste={onPaste}>      
      <Switch>
        <Route path="/detail/package03">
          <Package03 />
        </Route>
        <Route path="/detail/package02">
          <Package02 />
        </Route>  
        <Route path="/detail/package01">
          <Package01 />
        </Route>                
        <Route path="/detail/homepage02">
          <Homepage02 />
        </Route>
        <Route path="/detail/homepage01">
          <Homepage01 />
        </Route>        
        <Route path="/detail/sns01">
          <Sns01 />
        </Route>       
        <Route path="/detail/naver03">
          <Naver03 />
        </Route>
        <Route path="/detail/naver02">
          <Naver02 />
        </Route>
        <Route path="/detail/naver01">
          <Naver01 />
        </Route>        
        <Route path="/detail/youtube06">
          <Youtube06 />
        </Route>
        <Route path="/detail/youtube05">
          <Youtube05 />
        </Route>
        <Route path="/detail/youtube04">
          <Youtube04 />
        </Route>
        <Route path="/detail/youtube03">
          <Youtube03 />
        </Route>
        <Route path="/detail/youtube02">
          <Youtube02 />
        </Route>
        <Route path="/detail/youtube01">
          <Youtube01 />
        </Route>        
        <Route path="/detail/message03">
          <Message03 />
        </Route>
        <Route path="/detail/message02">
          <Message02 />
        </Route>
        <Route path="/detail/message01">
          <Message01 />
        </Route>        
        <Route path="/detail">
          <Detail />
        </Route>        
        <Route path="/">
          <Home /> 
        </Route>
      </Switch>
      <Footer />
      <KakaoChatPopup />
    </div>   
  );
}


export default App;