import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailHomepage02 from "../components/DetailHomepage02";
import GobackHomepage from "../components/GoBackHomepage";

function Homepage02() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GobackHomepage />           
      <DetailHomepage02 />      
    </>    
  )
}

export default Homepage02;