import styled from "styled-components";
import homepage02 from "../img/homepage/hp02.png";

const Wrapper = styled.div`
  max-width : 900px;  
  margin: auto;
  position: relative;
`;
const TopContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-wrap: wrap;  
  @media screen and (max-width:500px) {        
   margin-left: 1vw;   
  }  
`;
const ProductImg = styled.div`
  width: 55%;
  height: 100%;  
  display: flex;
  @media screen and (max-width:500px) {
    width: 100%;
    height: 60%;       
  }
`;
const Img = styled.div`
  width: 360px;
  height: 360px;
  background-image: url(${homepage02});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  justify-content: center;
  align-items: center;
  @media screen and (max-width:500px) {
    width: 250px;
    height: 250px;    
  } 
`;
const Overview = styled.div`
  width: 45%;  
  padding: 3vh 1vw; 
  @media screen and (max-width:500px) {
    flex: 0 1 100%;
    padding: 1vh 0;
    height: 40%;    
  }
`;
const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 1.3;
  @media screen and (max-width:500px) {
    font-size: 1.5rem;
    text-align: center;
    padding: 1vh 0;
  }
`;
const SubTitle = styled.h2`
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 1.5vh;
  @media screen and (max-width:500px) {
    font-size: 1.3rem;
    margin-bottom: 1vh;
    text-align: center;
  }
`;
const Price = styled.p`
  font-size: 2rem;    
  color: #e23e3e;
  padding: 1vh 0;
  @media screen and (max-width:500px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;
const UnitText = styled.p`
  @media screen and (max-width:500px) {    
    text-align: center;
  }
`;
const PayBtn = styled.div`
  width: 300px;
  height: 45px;
  background-color: #f8bfbf;
  border-radius: 5px;
  margin: 5vh 0;
  position: relative ;  
  a {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    font-size: 1.2rem;
    text-align: center;    
  }
  &:hover {
    box-shadow: 1px 3px 5px rgb(0, 0, 0, 0.3);
  }
  @media screen and (max-width:500px) {
    width: 150px;
    height: 35px;
    left: 0;
    right: 0;
    bottom: 20%;
    margin: 3vh auto;
    a { font-size: 1.1rem; }
  }
`;
const BottomContainer = styled.div`
   
`;
const DescContainer = styled.div`
  width: 90%;    
  margin: 5vh auto;  
`;
const Desc = styled.div`
  padding-left: 3vw;
  margin-top: 5vh;
  p {
    line-height: 1.5;
  }
  @media screen and (max-width:500px) {
    p {
      max-width: 330px;                 
    }
  }  
`;

function DetailHomepage02() {
  return (
    <Wrapper>              
      <TopContainer>
        <ProductImg>
          <Img></Img>
        </ProductImg>
        <Overview>
          <Title>홈페이지 제작</Title>
          <SubTitle>유튜브 연동 홈페이지</SubTitle>
          <Price>3,300,000원</Price>
          <UnitText>(1건 단위)</UnitText>
          <PayBtn>
            <a href="https://link.payple.kr/NzU6MTY0Mzg2ODc2Mg" target="blank" rel="noreferrer noopener">결제하기</a>
          </PayBtn>
        </Overview>
      </TopContainer>
      <BottomContainer>
        <DescContainer>          
          <Desc>
            <SubTitle>상품 소개</SubTitle>
            <p>유튜브가 연동되는 홈페이지‌‌</p>
            <p>※ 최소 3,300,000원부터‌</p>
            <p>&nbsp;</p>
            <p>● 회사 소개</p>
            <p>● 호스팅</p>
            <p>● 도메인 구입 및 유지</p>
            <p>● 배너</p>
            <p>● 수정 가능 횟수 3회</p>
            <p>● 소요 기간 30일</p>
          </Desc>          
          <Desc>
            <SubTitle>주문 전 확인 사항</SubTitle>
            <p>※ 상기된 가격은 부가세 포함입니다.</p> 
            <p>※ 홈페이지 제작 이후 유지 보수 비용은 별도 문의 바랍니다.</p>   
          </Desc>
        </DescContainer>
      </BottomContainer>
    </Wrapper>
  );
}

export default DetailHomepage02;