import styled from "styled-components";
import { motion } from "framer-motion";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import logo from "../img/고팀장 로고.png";

const Wrapper = styled.nav`
  width: 100%;
  height: 7vh;    
  display: flex;
  flex-wrap: wrap;  
  position: fixed;
  z-index: 10;  
  background-color: rgba(255, 255, 255, 1);  
  @media screen and (max-width:700px) {    
    justify-content: space-between;
  }
  @media screen and (min-width:500px) and (max-width:1300px) {
    height: 10vh;
    //4k 사이즈에서 높이 깨지는 것 방지
  } 
`;

const Logo = styled.div`
  flex: 0 1 20%;  
  display: flex;
  justify-content: center;
  align-items: center;  
`;
const LogoImg = styled.img`
  width: 100px;
  height: 40px;        
  cursor: pointer;
  @media screen and (max-width:700px) {
    padding-left: 1vw;
    width: 80px;
    height: 30px;      
  }  
`;
const MenuContainer = styled.ul`
  flex: 0 1 70%;  
  display: flex;
  justify-content: space-around;
  align-items: center;  
  @media screen and (max-width:700px) {
    display: none;
  } 
`;
const Menu = styled.li`
  font-size: 1.2rem;
  cursor: pointer;
  &:first-child {
    margin-left: 25vw;        
  }
  a {
    padding:2vh 2vw;
    &:hover {    
    color: rgb(255, 118, 117);
    }
  } 
  @media screen and (min-width:700px) and (max-width: 1000px) {
    font-size: 1.3rem;
    &:first-child {
      margin-left: 18vw;
    }
  }
`;
const MobileBtn = styled.div`
  display: none;
  i {display: none;}
  @media screen and (max-width:700px) {
    display: block;
    padding-right: 5vw;
    padding-top: 1.5vh;
    i {
      display: block;
      font-size: 30px;
    }
  }  
`;
const MobileNav = styled.div`
  width: 35vw;
  height: 100vh;  
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0vh;
  right: 0;
  ul {    
    li {      
      font-size: 1.2rem;
      cursor: pointer;
      line-height: 7vh;
      a {
        display: block;
        width: 35vw;
        height: 7vh;
        padding-left: 5vw;
      }     
    }
  }
  
`;
const Overlay = styled(motion.div)`
  position: fixed;    
  top: 0vh;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;  
`;

function Nav() {      
  //햄버거 버튼 구현(mobile)
  const [isVisible, SetVisible] = useState(false);
  const onClick = () => {
    SetVisible((current) => !current);
  }
  //nav bar 높이 제외하고 스크롤 위치 구현
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "auto"
    });
  }    
  return (
    <Wrapper>
      <Logo>
        <HashLink to = "/#main">
          <LogoImg src={logo} />
        </HashLink>               
      </Logo>
      <MenuContainer>
        <Menu>
          <HashLink to="/#main">홈</HashLink>  
        </Menu>          
        <Menu>
          <HashLink to="/#service" scroll={el => scrollWithOffset(el, 57)}>서비스 소개</HashLink>          
        </Menu>
        <Menu>
          <HashLink to="/detail#top">둘러보기</HashLink>
        </Menu>
        <Menu>
          <HashLink to="/#ask">문의하기</HashLink>  
        </Menu>         
      </MenuContainer>
      <MobileBtn onClick={onClick}>
        <i className="fas fa-bars"></i>
      </MobileBtn>      
      {isVisible && (
      <>
        <Overlay onClick={onClick} animate = {{ opacity: 1}} exit = {{opacity: 0}}/>  
        <MobileNav onClick = {() => SetVisible(false)}>
          <ul>
            <li><HashLink to="/#main">홈</HashLink></li>
            <li><HashLink to="/#service" scroll={el => scrollWithOffset(el, 65)} >서비스 소개</HashLink></li>
            <li><HashLink to="/detail#top">둘러보기</HashLink></li>
            <li><HashLink to="/#ask">문의하기</HashLink></li>
          </ul>
        </MobileNav>
      </>
      )}          
    </Wrapper>
  );
}

export default Nav;