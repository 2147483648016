import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

const Wrapper = styled.div`
  margin-bottom: 6vh;
`;
const Title = styled.h1`
  padding: 5vh 0;
  max-width: 1000px;
  margin: auto;    
  font-size: 40px;  
  @media screen and (max-width: 1000px) {
    max-width: 500px;
    padding-left: 5px;
    font-size: 30px;
  }  
`;
const CategoryContainer = styled.ul`
  max-width: 1000px;  
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;  
  @media screen and (max-width: 500px) {
    max-width: 360px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    max-width: 500px;
    grid-template-columns: repeat(2, 1fr);
  }
`;
const CategoryItem = styled.li`  
  margin-bottom: 10px;
  border: 1px solid  #e23e3e;
  border-radius: 10px;
  height:35px;  
`;
const LinkContainer = styled.div`
  width: 100%;
  height: 100%;
  padding:1vh 0;
  display:flex;
  justify-content : center;
  align-items: center;  
  &:hover {
    border-radius: 10px;    
    background-color: rgba(226, 62, 62, 0.1);   
  }
`;
const Text = styled.p`
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.5;  
`;
function Category() {  
  return (
    <Wrapper>
      <Title>상품 카테고리</Title>
      <CategoryContainer>
        <CategoryItem>
          <HashLink to="/detail#advertising">
            <LinkContainer>
              <Text>메시지 광고 발송</Text>
            </LinkContainer>
          </HashLink>          
        </CategoryItem>
        <CategoryItem>
          <HashLink to="/detail#youtube">
            <LinkContainer>
              <Text>유튜브 채널</Text>
            </LinkContainer>
          </HashLink>
        </CategoryItem>
        <CategoryItem>
          <HashLink to="/detail#naver">
            <LinkContainer>
              <Text>네이버 블로그</Text>
            </LinkContainer>
          </HashLink>          
        </CategoryItem>
        <CategoryItem>
          <HashLink to="/detail#sns">
            <LinkContainer>
              <Text>SNS</Text>
            </LinkContainer>
          </HashLink>        
        </CategoryItem>
        <CategoryItem>
          <HashLink to="/detail#homepage">
            <LinkContainer>
              <Text>홈페이지</Text>
            </LinkContainer>
          </HashLink>
        </CategoryItem>
      </CategoryContainer>  
    </Wrapper>
  );
}

export default Category;