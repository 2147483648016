import NavHome from "../components/NavHome";
import Main from "../components/Main";
import Service from "../components/Service";
import Ask from "../components/Ask";
import AcademyBanner from "../components/AcademyBanner";
import FigureCountUp from "../components/FigureCountUp";

function Home() {
  return (
    <>
      <NavHome />            
      <Main />
      <FigureCountUp />          
      <Service />
      <AcademyBanner />  
      <Ask />         
    </>
  );
}

export default Home;