import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailYoutube03 from "../components/DetailYoutube03";
import GoBackYoutube from "../components/GoBackYoutube";


function Youtube03() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackYoutube />      
      <DetailYoutube03 />      
    </>    
  )
}

export default Youtube03;