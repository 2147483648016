import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailMessage01 from "../components/DetailMessage01";
import GoBackAdvertising from "../components/GoBackAdvertising";

function Message01() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackAdvertising />      
      <DetailMessage01 />      
    </>    
  )
}

export default Message01;