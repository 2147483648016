import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import GoBackAdvertising from "../components/GoBackAdvertising";
import DetailPackage01 from "../components/DetailPackage01";

function package01() {
  return (
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackAdvertising />
      <DetailPackage01 />      
    </>    
  );
}

export default package01;