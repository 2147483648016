import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailHomepage01 from "../components/DetailHomepage01";
import GobackHomepage from "../components/GoBackHomepage";

function Homepage01() {
  return(
    <>
      <NavDetail />
      <DetailBanner /> 
      <GobackHomepage />     
      <DetailHomepage01 />      
    </>    
  )
}

export default Homepage01;