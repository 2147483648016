import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import GoBackNaver from "../components/GoBackNaver";
import DetailPackage03 from "../components/DetailPackage03";

function package03() {
  return (
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackNaver />
      <DetailPackage03 />      
    </>
  );
}

export default package03;