import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailYoutube06 from "../components/DetailYoutube06";
import GoBackYoutube from "../components/GoBackYoutube";


function Youtube06() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackYoutube />      
      <DetailYoutube06 />            
    </>    
  )
}

export default Youtube06;