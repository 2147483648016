import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

const Wrapper = styled.div`  
  margin: auto;
  max-width: 800px;
  padding: 7vh 0; 
  h1 {
    text-align: center;      
    line-height: 1.5;
    font-size: 40px;
    border-bottom: 2px solid rgb(255, 118, 117);
  }
  @media screen and (max-width:800px) {    
    h1 {
      font-size: 1.8rem;
      line-height: 2.1;
    }
  }
  @media screen and (min-width: 800px) and (max-width:1300px) {    
    h1 {
      font-size: 2rem;      
    }
  }
`;
const Desc = styled.ul`
  padding: 3vh 0;
  li {
    line-height: 1.5;
    text-align: center;
    font-size: 19px;
    &:nth-of-type(2) {
        padding: 1vh 0;
      }
    @media screen and (max-width:800px) {
      font-size: 1.2rem;
    }
    @media screen and (min-width: 800px) and (max-width:1300px) {
      font-size: 1.3rem;
    }
  }  
`;
const Promotion = styled.ul`
  max-width: 600px;
  margin: auto;
  padding-left: 10px;
  li {
    line-height: 1.5;     
    display: grid;
    grid-template-columns: 1fr 2fr;    
    p:first-child {
      font-size: 24px;
      font-weight: bold;
    }
    p:last-child {
      font-size: 19px;
      line-height: 2;
    }
    @media screen and (max-width:800px) {
      text-align: center;
      grid-template-columns: 1fr;
      gap:0;
      p:first-child {
        line-height: 0.8;
        font-size: 1.5rem;
      }
      p:last-child {
        font-size: 1rem;
        line-height : 3.5;
      }
    }
    @media screen and (min-width: 800px) and (max-width:1300px) {
      p:first-child {
        line-height: 1.5;
        font-size: 2rem;
      }
      p:last-child {
        font-size: 1.2rem;
        line-height : 2.5;
      }
    }
  }
`;

const DetailBtn = styled.div`  
  margin: 3vh auto;
  width: 150px;
  height: 40px;  
  border-radius: 10px;
  background-color:  rgb(255, 118, 117);
  display: flex;
  justify-content: center;
  align-items: center;  
  text-align: center;  
  color:white;    
  &:hover {
    border-radius: 10px;
    box-shadow: 1px 3px 5px rgb(0, 0, 0, 0.3);
  }  
`;

function Service() {
  return (
    <Wrapper>
      <h1>" 광고마케팅 전문가 고팀장 "</h1>
        <Desc>
          <li>
            <p>소기업 대표님들!</p>
            <p>제대로 광고 마케팅하세요</p>
          </li>
          <li>
            <p>광고 마케팅 전문가 고팀장이</p> 
            <p>책임지고 만들어서 배포하겠습니다.</p>
          </li>
        </Desc>
        <Promotion>
          <li>
            <p>광고 집행</p>
            <p>메시지, 직투 등</p>
          </li>
          <li>
            <p>마케팅 집행</p>
            <p>홈페이지, 네이버 블로그, 유튜브, SNS 제작 등</p>
          </li>
        </Promotion>
        <HashLink to = "/detail">
          <DetailBtn>          
              상품 둘러보기         
          </DetailBtn>
        </HashLink>        
    </Wrapper>
  );
}

export default Service;