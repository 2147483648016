import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailYoutube05 from "../components/DetailYoutube05";
import GoBackYoutube from "../components/GoBackYoutube";


function Youtube05() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackYoutube />      
      <DetailYoutube05 />      
    </>    
  )
}

export default Youtube05;