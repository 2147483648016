import styled from "styled-components";
import bottomBar from "../img/1.png";

const Wrapper = styled.div``;
const BgImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${bottomBar});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;  
`;
const Container = styled.ul`
  max-width: 1000px;
  margin: auto;
  padding: 5vh 0;
  display: flex;
  flex-wrap: wrap;  
  @media screen and (max-width: 900px) {
    max-width: 300px;
    padding: 6vh 0;
  }
`;
const Item = styled.li`  
  margin: auto;
  @media screen and (max-width: 900px) {
    padding-top: 3vh;
  }
`;
const Text = styled.h1`  
  font-size: 30px;  
  color: rgba(255, 255, 255);
  text-align: center;
  font-weight: bold; 
  @media screen and (max-width: 900px) {
    font-size: 24px;
    line-height: 1.5;
  }
`;
const BtnLink = styled.a``;
const AcademyBtn = styled.div`
  width: 160px;
  height: 40px;
  background-color: rgba(255, 255, 255);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b80009;
  font-weight: bold;
  &:hover {
    background-color: #b80009;
    color: white;   
  }  
`;


function AcademyBanner() {
  return (
    <Wrapper>
      <BgImg>
        <Container>
          <Item>
            <Text>1 : 1 컨설팅을 통해 직접 배워서 적용하기</Text>
          </Item>
          <Item>            
            <BtnLink href="https://www.creaction.kr/courses/11" target="blank" rel="noreferrer noopener">
              <AcademyBtn>아카데미 바로가기</AcademyBtn>
            </BtnLink>            
          </Item>           
        </Container>        
      </BgImg>
    </Wrapper>
  );
}

export default AcademyBanner;