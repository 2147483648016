import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailMessage02 from "../components/DetailMessage02";
import GoBackAdvertising from "../components/GoBackAdvertising";

function Message02() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackAdvertising />      
      <DetailMessage02 />      
    </>    
  )
}

export default Message02;