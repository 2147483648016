import styled from "styled-components";
import { motion, useAnimation, useViewportScroll } from "framer-motion";
import banner from "../img/1.png";
import rightImg from "../img/2.png";
import { useEffect } from "react";

const Wrapper = styled.div`
  height: 100vh;      
  background-image: url(${banner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  z-index: -1;
  @media screen and (max-width:800px) {
    width: 100vw;
    height: 95vh;
  }  
`;
const Container = styled.div`
  padding: 10vh 7vw;  
  @media screen and (max-width:800px) {
    padding-top: 12vh;
  }
`;
const Title = styled.div`
  max-width: 800px;
  p {
    font-family: 'Black Han Sans', sans-serif;
    font-size: 10rem;  
    color: white;
    line-height: 0.9;
    font-weight: 400;
    text-shadow: 5px 7px 10px rgb(0, 0, 0, 0.3);
    &:last-child {
      color: rgb(255, 185, 167);
    }
    @media screen and (max-width:800px) {      
      font-size: 5rem;
    }
    @media screen and (min-width: 800px) and (max-width:1300px) {
      font-size: 7rem;
    }
  }  
`;
const Slogan = styled.div`
  max-width: 600px;
  padding: 3vh 1vw;
  p {
    color: white;
    font-size: 2rem;
    line-height: 1.3;
    text-shadow: 1px 2px 3px rgb(0, 0, 0, 0.3);
    span {
      color: #f4d7c1;
      font-size: 2.1rem;
      font-weight: bold;
    } 
  }
  @media screen and (max-width:800px) {
    padding: 6.5vh 5px;
    p {
      font-size: 1.2rem;
      span {
        font-size: 1.3rem;
      }
    }
  }
  @media screen and (min-width: 800px) and (max-width:1300px) {
      p {
        font-size: 1.5rem;
      }
    }
`;
const Tel = styled.div`
  padding: 1vh 1vw;  
  a {
    color: white;
    font-size: 3rem;
    text-shadow: 1px 2px 3px rgb(0, 0, 0, 0.3);
    @media screen and (max-width:800px) {
      font-size: 1.5rem;
    } 
    @media screen and (min-width: 800px) and (max-width:1300px) {
      font-size: 1.7rem;
    } 
  }  
`;
const Arrow = styled(motion.span)`
  width: 50px;
  height: 50px;
  color: rgb(255, 255, 255);
  text-shadow: 1px 2px 3px rgb(0, 0, 0, 0.3);
  position: absolute;
  left: 50%;  
  transform: translate(-50%,-50%);
  z-index: 1;
  animation: arrow_ani 0.8s infinite alternate;  

  @keyframes arrow_ani {
  from {
    bottom: 1%;    
  }
  to {
    bottom: 3%;
  } 
} 
  @media screen and (max-width:800px) {
    i {display: none;}     
  }
`;
const RightImg = styled.div`
  width: 50%;
  height: 60%;
  background: url(${rightImg}) center/cover;
  position: absolute;
  bottom: -3.5%;
  right: 0;  
  @media screen and (max-width:800px) {
    width: 300px;
    height: 300px;    
  }
`;

//variants
const arrowVars = {
  visible: {
    display: "block",
  },
  nonVisible: {
    display: "none",
  }
}

function Main() {
  const arrowAni = useAnimation();
  const { scrollY } = useViewportScroll();
  useEffect(() => {
    scrollY.onChange(() => {
      if(scrollY.get() < 100 && window.innerWidth > 800) {
        arrowAni.start("visible");
      } else {
        arrowAni.start("nonVisible");
      }
    })
  },[scrollY, arrowAni]);
  return (
    <Wrapper id = "main">      
      <Container>
        <Title>
          <p>광고</p>
          <p>마케팅은</p>
          <p>고팀장</p>
        </Title>
        <Slogan>
          <p>대표님, 더 이상 스트레스 받지 마세요</p>
          <p>광고 마케팅은 <span>고팀장</span>이 하겠습니다</p>
        </Slogan>
        <Tel>        
          <a href = 'tel: 070-8779-7288'><i className="fas fa-phone-alt"></i> 070-8779-7288</a>      
        </Tel>
      </Container>
      <Arrow 
        initial="visible" 
        variants={arrowVars} 
        animate={arrowAni}  
      >      
        <i className="fas fa-angle-double-down fa-4x"></i>
      </Arrow>    
      <RightImg />
    </Wrapper>
  );
}

export default Main;