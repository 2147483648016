import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import DetailSNS01 from "../components/DetailSNS01";
import GoBackSns from "../components/GoBackSns";


function Sns01() {
  return(
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackSns />      
      <DetailSNS01 />      
    </>    
  )
}

export default Sns01;