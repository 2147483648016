import styled from "styled-components";
import { Link } from "react-router-dom";
import adMessage01 from "../img/ad/ad01.png";
import adMessage02 from "../img/ad/ad02.png";
import adMessage03 from "../img/ad/ad03.png";
import packageMessage from "../img/package/packageMessage.png";


const Wrapper = styled.div`
  
`;
const AdContainer = styled.div`
  max-width: 1000px;  
  margin: auto;  
  h2 {    
    font-size: 35px;
    padding-top: 7vh;
    @media screen and (max-width:1000px) {
      font-size: 28px;
    }
    @media screen and (min-width:500px) and (max-width:1300px) {
      padding-top: 10vh;
      //4k 사이즈에서 높이 깨지는 것 방지
  }  
  }
  @media screen and (max-width: 500px) {
    max-width: 380px;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    max-width: 500px;
  }
   
`;
const MessageList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 5vh 0;
  @media screen and (max-width:500px) {       
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width:500px) and (max-width:1000px){
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
  }    
`;
const Message = styled.li`  
  width: 320px;
  height: 320px;  
  position: relative;
  overflow: hidden;
  margin-bottom: 5vh; 
  border-radius: 10px;
  @media screen and (max-width:500px) {    
    width: 180px;
    height: 180px;    
  }
  @media screen and (min-width:500px) and (max-width: 1000px) {
    width: 240px;
    height: 240px;    
  }   
`;
const BgCover = styled.div`
  width: 100%;
  height: 100%;  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease;
  cursor: pointer;  
  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  } 
`;
const ListTitle = styled.div`
  position: absolute;  
  padding: 1% 5%;
  width: 100%;
  bottom: 0;  
  background-color: rgba(255, 255, 255, 0.8);
  p {
    line-height: 1.5;
    @media screen and (max-width:500px) {      
      font-size: 0.8rem;    
    }
  } 
`;

function ListAdvertising() {
  return (
    <Wrapper>
      <AdContainer>
        <h2 id ="advertising">메시지 광고 발송</h2>
        <MessageList >
          <Message>
            <Link to = "/detail/package01">
              <BgCover style={{backgroundImage: `url(${packageMessage})`}} />
              <ListTitle>
                <p>메시지 패키지</p>
                <p>1,232,000원</p>
              </ListTitle>
            </Link>
          </Message>
          <Message>
            <Link to = "/detail/message01">
              <BgCover style={{backgroundImage: `url(${adMessage01})`}} />
              <ListTitle>
                <p>메시지 발송 - 수동 타깃 세팅</p>
                <p>220,000원 (1회 단위)</p>
              </ListTitle>
            </Link>                                                 
          </Message>          
          <Message>
            <Link to = "/detail/message02">
              <BgCover style={{backgroundImage: `url(${adMessage02})`}} />
              <ListTitle>
                <p>메시지 발송 - 내용 구성</p>
                <p>220,000원 (1회 단위)</p>
              </ListTitle>
            </Link>                          
          </Message>
          <Message>
            <Link to = "/detail/message03">
              <BgCover style={{backgroundImage: `url(${adMessage03})`}} />
              <ListTitle>
                <p>메시지 발송 - 광고메시지 발송</p>
                <p>110,000원 (1,000건 단위)</p>
              </ListTitle> 
            </Link>          
          </Message>
        </MessageList>
      </AdContainer>                 
    </Wrapper>
  );
}

export default ListAdvertising;