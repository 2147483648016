import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

const Wrapper = styled.div`
  max-width: 850px;
  margin: 2vh auto;   
`;
const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: auto;
  @media screen and (max-width:500px) {
    width: 80vw;           
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width:500px) and (max-width:1000px){
    width: 70vw;    
    grid-template-columns: repeat(3, 1fr);
  }     
`;
const Category = styled.li` 
  margin: auto;
`;
const Btn = styled.div`
  width: 160px;
  height: 45px;  
  border: 1px solid #e23e3e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 10px;    
    background-color: rgba(226, 62, 62, 0.1);   
  }  
  @media screen and (max-width:500px) {
    width: 150px;
    height: 40px;
  }  
`;
const Text = styled.p`
  text-align: center;
  line-height: 1.2;
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;   
  } 
`;

function GoBackAdvertising() {
  return (
    <Wrapper>
      <Container>
        <Category>
          <HashLink to = "/detail/#advertising">
            <Btn>            
              <Text>전체 상품</Text>             
            </Btn>
          </HashLink>          
        </Category>
        <Category>
          <HashLink to = "/detail/package01">
            <Btn>
              <Text>메시지 패키지</Text>
            </Btn>   
          </HashLink>        
        </Category>
        <Category>                
          <HashLink to = "/detail/message01">
            <Btn>
              <Text>메시지 수동 타깃 세팅</Text>              
            </Btn>
          </HashLink>       
        </Category>
        <Category> 
          <HashLink to = "/detail/message02">             
            <Btn>
              <Text>메시지 내용 구성</Text>
            </Btn>            
          </HashLink> 
        </Category>
        <Category>
          <HashLink to = "/detail/message03">              
            <Btn>
              <Text>메시지 광고 발송</Text>
            </Btn>            
          </HashLink> 
        </Category>
      </Container>      
                    
    </Wrapper>
  );
}

export default GoBackAdvertising;