import styled from "styled-components";
import detailBanner from "../img/1.png";

const Wrapper = styled.div`  
  height: 40vh;  
  @media screen and (max-width:800px) {
    height: 30vh;
  }
`;
const BgImg = styled.div`
  height: 100%;
  background-image: url(${detailBanner});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 500px) and (max-width: 1300px) {
    padding-top: 10vh;
  }
`;
const Title = styled.h1`
  max-width: 600px;
  margin: auto;
  font-family: 'Black Han Sans', sans-serif;
  font-size: 4rem;
  text-align: center;
  color: rgba(255, 255, 255);  
  @media screen and (max-width:800px) {
    font-size: 3rem;
  }
`;

function DetailBanner() {
  return (
    <Wrapper>
      <BgImg>
        <Title>상품 둘러보기</Title>
      </BgImg>      
    </Wrapper>
  );
}

export default DetailBanner;