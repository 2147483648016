import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

const Wrapper = styled.div`
  max-width: 800px;
  margin: 2vh auto;   
`;
const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 7px;
  margin: auto;
  @media screen and (max-width:500px) {
    width: 80vw;           
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width:500px) and (max-width:1000px){
    width: 90vw;    
  }      
`;
const Category = styled.li`   
`;
const Btn = styled.div`
  width: 160px;
  height: 45px;  
  border: 1px solid #e23e3e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 10px;    
    background-color: rgba(226, 62, 62, 0.1);   
  }  
  @media screen and (max-width:500px) {
    width: 150px;
    height: 40px;
  }  
`;
const Text = styled.p`
  text-align: center;
  line-height: 1.2;      
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;   
  }
`;

function GoBackHomepage() {
  return (
    <Wrapper>
      <Container>
        <Category>
          <HashLink to = "/detail/#homepage">
            <Btn>
              <Text>전체 상품</Text>
            </Btn>
          </HashLink>
        </Category>       
        <Category>          
          <HashLink to = "/detail/homepage01">
            <Btn>
              <Text>홈페이지 싱글페이지</Text>
            </Btn>              
          </HashLink>
        </Category>
        <Category>
          <HashLink to = "/detail/homepage02">
            <Btn>
              <Text>홈페이지 유튜브 연동</Text>
            </Btn>              
          </HashLink>
        </Category>
      </Container>      
                    
    </Wrapper>
  );
}

export default GoBackHomepage;