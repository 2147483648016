import styled from "styled-components";
import youtube05 from "../img/youtube/y05.png";

const Wrapper = styled.div`
  max-width : 900px;  
  margin: auto;
  position: relative;      
`;
const TopContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-wrap: wrap;  
  @media screen and (max-width:500px) {        
   margin-left: 1vw;   
  } 
`;
const ProductImg = styled.div`
  width: 55%;
  height: 100%;  
  display: flex;
  @media screen and (max-width:500px) {
    width: 100%;
    height: 60%;    
  } 
`;
const Img = styled.div`
  width: 360px;
  height: 360px;
  background-image: url(${youtube05});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  justify-content: center;
  align-items: center;
  @media screen and (max-width:500px) {
    width: 250px;
    height: 250px;    
  } 
`;
const Overview = styled.div`
  width: 45%;  
  padding: 3vh 1vw; 
  @media screen and (max-width:500px) {
    flex: 0 1 100%;
    padding: 1vh 0;
    height: 40%;    
  }
`;
const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 1.3;
  @media screen and (max-width:500px) {
    font-size: 1.5rem;
    text-align: center;
    padding: 1vh 0;
  }
`;
const SubTitle = styled.h2`
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 1.5vh;
  @media screen and (max-width:500px) {
    font-size: 1.3rem;
    margin-bottom: 1vh;
    text-align: center;
  }
`;
const Price = styled.p`
  font-size: 2rem;    
  color: #e23e3e;
  padding: 1vh 0;
  @media screen and (max-width:500px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;
const UnitText = styled.p`
  @media screen and (max-width:500px) {    
    text-align: center;
  }
`;
const PayBtn = styled.div`
  width: 300px;
  height: 45px;
  background-color: #f8bfbf;
  border-radius: 5px;
  margin: 5vh 0;
  position: relative ;  
  a {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    font-size: 1.2rem;
    text-align: center;    
  }
  &:hover {
    box-shadow: 1px 3px 5px rgb(0, 0, 0, 0.3);
  }
  @media screen and (max-width:500px) {
    width: 150px;
    height: 35px;
    left: 0;
    right: 0;
    bottom: 20%;
    margin: 3vh auto;
    a { font-size: 1.1rem; }
  }
`;
const BottomContainer = styled.div`
       
`;
const DescContainer = styled.div`
  width: 90%;
  margin: 5vh auto;
`;
const Desc = styled.div`
  padding-left: 3vw;
  margin-top: 5vh;
  p {
    line-height: 1.5;
  }
  @media screen and (max-width:500px) {
    p {
      max-width: 330px;
    }
  }
`;

function DetailYoutube05() {
  return (
    <Wrapper>              
      <TopContainer>
        <ProductImg>
          <Img></Img>
        </ProductImg>
        <Overview>
          <Title>유튜브 채널</Title>
          <SubTitle>소개 홍보 영상</SubTitle>
          <Price>2,200,000원</Price>
          <UnitText>(1건 단위)</UnitText>
          <PayBtn>
            <a href="https://link.payple.kr/NzU6MTY0Mzg2ODMzNg" target="blank" rel="noreferrer noopener">결제하기</a>
          </PayBtn>
        </Overview>
      </TopContainer>
      <BottomContainer>
        <DescContainer>          
          <Desc>
            <SubTitle>상품 소개</SubTitle>
            <p>채널을 대표할 수 있는 소개 및 홍보 영상‌</p>
            <p>※ 최소 2,200,000원부터</p>
          </Desc>          
          <Desc>
            <SubTitle>주문 전 확인 사항</SubTitle>
            <p>※ 동영상 콘텐츠는 요구 사항에 따라 작업량의 범위가 넓어서 협의 후 진행합니다.</p>
            <p>※ 동영상 1건당 최소 단가 기준 적용 금액이며, 품질 및 수량에 따라 가격은 조정됩니다.</p>
            <p>※ 상기된 가격은 부가세 포함입니다.</p>
            <p>※ 촬영에 필요한 장비 렌트, 성우, 모델료 등 비용 별도</p>                     
          </Desc>
        </DescContainer>
      </BottomContainer>
    </Wrapper>
  );
}

export default DetailYoutube05;