import NavDetail from "../components/NavDetail";
import DetailBanner from "../components/DetailBanner";
import GoBackYoutube from "../components/GoBackYoutube";
import DetailPackage02 from "../components/DetailPackage02";

function package02() {
  return (
    <>
      <NavDetail />
      <DetailBanner />
      <GoBackYoutube />
      <DetailPackage02 />      
    </>
  );
}

export default package02;